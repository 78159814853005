var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticStyle:{"padding-top":"15px","display":"inline-block"}},[_c('span',[_c('h5',{staticClass:"title",staticStyle:{"font-weight":"350","padding-bottom":"10px"}},[_c('small',[_vm._v(_vm._s(_vm.tipoProprietario)+" id: "+_vm._s(_vm.idServidor))])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkPapel()),expression:"checkPapel()"}],staticClass:"switch-div"},[_c('md-switch',{model:{value:(_vm.editionMode),callback:function ($$v) {_vm.editionMode=$$v},expression:"editionMode"}},[_vm._v("Modo Edição ")])],1),_c('div',{staticClass:"md-layout md-gutter md-alignment-center",attrs:{"id":"container "}},[_c('div',{staticClass:"md-layout-item"},[_c('h4',{staticClass:"title",staticStyle:{"font-weight":"350"}},[_vm._v(" Dependente : ")])]),_c('div',[_c('md-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showButton),expression:"showButton"}],staticClass:"md-raised mt-4 md-success",attrs:{"type":"button","disabled":_vm.checkEditionDisabled()},on:{"click":function($event){return _vm.addNovoDependente()}}},[_vm._v(" Adicionar ")])],1)]),_vm._l((_vm.dependentes),function(dependente,index){return _c('div',{key:index},[(!_vm.isFetching)?_c('div',{staticStyle:{"padding-top":"15px","display":"inline-block"}},[_c('span',[_c('h5',{staticClass:"title",staticStyle:{"font-weight":"350","padding-bottom":"10px"}},[_vm._v(" Dependente - "+_vm._s(index)+" ")])])]):_vm._e(),(
            dependente.idDependente === '' || dependente.idEntidade === ''
          )?_c('div',{staticClass:"md-layout-item md-size-50 md-small-size-100",staticStyle:{"padding-left":"15px","display":"inline-block"}},[_c('ValidationProvider',{attrs:{"name":"pesquisa"}},[_c('md-autocomplete',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkPapel()),expression:"checkPapel()"}],staticClass:"uppercase",attrs:{"md-options":_vm.dependentesOptionAuto,"md-fuzzy-search":false,"disabled":_vm.checkEditionDisabled()},on:{"md-selected":function($event){return _vm.selectedDependente(index)}},model:{value:(_vm.pesquisas[index].pesquisa.texto),callback:function ($$v) {_vm.$set(_vm.pesquisas[index].pesquisa, "texto", $$v)},expression:"pesquisas[index].pesquisa.texto"}},[_c('label',[_vm._v("Pesquisar CPF ou Nome")])])],1)],1):_vm._e(),_c('div',{staticClass:"md-layout",attrs:{"id":"agrupamentoCampos"}},[_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-50"},[_c('ValidationProvider',{attrs:{"name":'Nome Completo-' + index,"rules":"required|min:3|max:100"},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Nome Completo")]),_c('md-input',{staticClass:"uppercase",attrs:{"type":"text","maxlength":100,"disabled":_vm.checkEditionDisabled()},model:{value:(dependente.nomeCompleto),callback:function ($$v) {_vm.$set(dependente, "nomeCompleto", $$v)},expression:"dependente.nomeCompleto"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-15"},[_c('ValidationProvider',{attrs:{"name":'CPF-' + index,"rules":{
                regex: /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/,
                min: 14,
                max: 14,
                required: true
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("CPF")]),_c('md-input',{attrs:{"type":"text","maxlength":14,"disabled":_vm.checkEditionDisabled()},model:{value:(dependente.cpfDependente),callback:function ($$v) {_vm.$set(dependente, "cpfDependente", $$v)},expression:"dependente.cpfDependente"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s("CPF dever ser no formato 999.999.999-99")+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-15"},[_c('ValidationProvider',{attrs:{"name":'Data Nascimento-' + index,"rules":{
                regex: /^([0-2]\d{1}|3[0-1])\/(0\d{1}|1[0-2])\/(19|20)(\d{2})$/,
                min: 10,
                max: 10,
                required: true
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Data de Nascimento")]),_c('md-input',{attrs:{"type":"text","maxlength":10,"disabled":_vm.checkEditionDisabled()},model:{value:(dependente.dataNascimento),callback:function ($$v) {_vm.$set(dependente, "dataNascimento", $$v)},expression:"dependente.dataNascimento"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s("Data deve ter formato DD/MM/AAAA")+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-10"},[_c('md-field',[_c('label',[_vm._v("Idade ")]),_c('md-input',{attrs:{"type":"numeric","maxlength":3,"disabled":"","value":_vm.idadeSetValue(index)}})],1)],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-25"},[_c('ValidationProvider',{attrs:{"name":'Sexo-' + index,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',[_vm._v("Sexo ")]),_c('md-radio',{attrs:{"value":"M","disabled":_vm.checkEditionDisabled()},model:{value:(dependente.sexoDependente),callback:function ($$v) {_vm.$set(dependente, "sexoDependente", $$v)},expression:"dependente.sexoDependente"}},[_vm._v("Masculino")]),_c('md-radio',{attrs:{"value":"F","disabled":_vm.checkEditionDisabled()},model:{value:(dependente.sexoDependente),callback:function ($$v) {_vm.$set(dependente, "sexoDependente", $$v)},expression:"dependente.sexoDependente"}},[_vm._v("Feminino")]),_c('br'),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-20"},[_c('ValidationProvider',{attrs:{"name":'Parentesco-' + index,"rules":{
                required: true
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',{attrs:{"for":"parentescoSelect"}},[_vm._v("Parentesco")]),_c('md-select',{attrs:{"name":"parentescoSelect","disabled":_vm.checkEditionDisabled()},model:{value:(dependente.parentesco),callback:function ($$v) {_vm.$set(dependente, "parentesco", $$v)},expression:"dependente.parentesco"}},_vm._l((_vm.parentescoSelectOption),function(item){return _c('md-option',{key:item.id,attrs:{"label":item.parentesco,"value":item.parentesco}},[_vm._v(" "+_vm._s(item.parentesco)+" ")])}),1)],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"col-xs-2"},[_c('label',[_vm._v(" ")]),_c('md-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showButton),expression:"showButton"}],staticClass:"md-just-icon md-danger",attrs:{"type":"button","disabled":_vm.checkEditionDisabled()},on:{"click":function($event){return _vm.removeDependente(index)}}},[_c('md-icon',[_vm._v("close")])],1),_c('label',{staticStyle:{"padding-left":"20px"}},[_vm._v(" ")]),_c('md-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showButton),expression:"showButton"}],staticClass:"md-raised md-success",attrs:{"type":"button","disabled":_vm.checkEditionDisabled()},on:{"click":function($event){return _vm.salvar(index)}}},[_vm._v(" SALVAR ")]),_vm._v("   ")],1)])})],2)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }